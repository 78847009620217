.a-clipper-instructions {
	width: 100%;

	img {
		width: 100%;
		max-width: 160px;
	}

	.a-clipper-arrow {
		height: 160px;
		display: flex;
		align-items: center;
		justify-content: center;

		@include media-breakpoint-down(lg) {
			height: 80px;
		}
	}
}

.a-clipper-media {
	> div {
		margin-left: -24px;
		margin-right: -24px;
	}

	.a-clipper-sizer {
		> div {
			width: 16.66%;
			flex: 0 1 16.66%;

			@include media-breakpoint-down(lg) {
				width: 20%;
				flex: 0 1 20%;
			}
		}
	}

	.a-clipper-sm {
		> div {
			width: 12.5%;
			flex: 0 1 12.5%;

			@include media-breakpoint-down(lg) {
				width: 16.66%;
				flex: 0 1 16.66%;
			}

			// @include media-breakpoint-down(xxxl) {
			//     width: 20%;
			//     flex: 0 1 20%;
			// }
		}
	}

	.a-clipper-lg {
		> div {
			width: 25%;
			flex: 0 1 25%;
		}
	}

	.a-clipper-md {
		/** add code here... */
	}

	.a-clipper-sm,
	.a-clipper-md,
	.a-clipper-lg {
		flex-wrap: wrap;
		// > div {

		//     @include media-breakpoint-down(xxl) {
		//         width: 25%;
		//         flex: 0 1 25%;
		//     }

		//     @include media-breakpoint-down(md) {
		//         width: 50%;
		//         flex: 0 1 50%;
		//     }

		//     @include media-breakpoint-down(sm) {
		//         width: 100%;
		//         flex: 0 1 100%;
		//     }
		// }
	}

	.a-clipper-media-item {
		margin-bottom: 24px;
		display: flex;

		&-handle {
			padding: 18px;
			transition: box-shadow 0.25s ease;
			width: 100%;

			&.active,
			&:hover {
				box-shadow: 0px 0px 20px #00000029;
				h6 {
					position: relative;
					/* &:after {
                        content: "\ecb5";
                        font-family: 'remixicon' !important;
                        font-style: normal;
                        -webkit-font-smoothing: antialiased;
                        opacity: .5;
                        margin-left: 5px;
                        position: relative;
                        top: 2px;
                    }
                    */
				}
			}
		}
	}

	img {
		width: 100%;
		margin: 5px 0;
		// max-width: 90%;
		// padding: 5px;
	}

	.dropdown {
		.btn {
			border: 0px;
			padding: 0px;
			min-width: 1px;
			width: 12px;
			border-radius: 0;

			&::after {
				display: none;
			}
		}
	}
}

@mixin aspect-ratio($width, $height) {
	position: relative;

	&:before {
		display: block;
		content: ' ';
		width: 100%;
		padding-top: calc(($height / $width) * 100%);
	}

	> .inner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

.image-container {
	@include aspect-ratio(1, 1);
	width: 100%;
	margin-bottom: 0.5rem;
	.inner {
		border-radius: 8px;
		overflow: hidden;
		img {
			height: 100%;
			width: auto;
			margin: 0;
		}
	}
}
