.a-action-bar {
	padding: 17px 32px;

	ul {
		margin-left: -10px;
		margin-right: -10px;
		li {
			padding: 0 10px;
			min-width: 100px;
			white-space: nowrap;
			color: $-color-beige;
			font-size: 12px;

			a {
				transition: all 0.4s ease;
				text-decoration: none;
				color: inherit;
				font-size: inherit;

				i {
					font-size: 16px;
					margin-right: 10px;
					padding: 0.09375rem 3px;
					border: 1px solid $-color-secondary-ash;
					border-radius: 4px;
					transition: all 0.4s ease;
				}

				&:hover,
				&:focus {
					color: white;

					i {
						background-color: $-color-white;
						border-color: $-color-white;
						color: $-color-charcoal;
					}
				}
			}
		}
	}

	&.vertical {
		width: 40px;
		justify-content: center;
		left: -28px;
		top: 0;
		padding: 8px 0;

		ul {
			li {
				min-width: 1px;
			}
		}
	}
}

.a-action-bar-float {
	position: fixed;
	bottom: 75px;
	padding-left: $-app-sidebar-width;
	left: 50%;
	transform: translateX(-50%);
	z-index: 300;
	transition: padding-left 0.45s ease;

	.a-action-bar {
		transform: translateX(-50%);
	}

	@include media-breakpoint-down($-app-responsive-size) {
		padding-left: 0px;
	}
}
