.progress-circle {
	width: 64px;
	height: 64px;
	background: none;
	position: relative;
}

.progress-circle {
	&::after {
		content: '';
		width: 100%;
		height: 100%;
		border-radius: 50%;
		border: 8px solid #eeede9;
		position: absolute;
		top: 0;
		left: 0;
	}

	&.primary::after {
		border: 8px solid #e7faf3;
	}
}

.progress-circle > span {
	width: 50%;
	height: 100%;
	overflow: hidden;
	position: absolute;
	top: 0;
	z-index: 1;
}

.progress-circle .progress-circle-left {
	left: 0;
}

.progress-circle .progress-circle-bar {
	width: 100%;
	height: 100%;
	background: none;
	border-width: 8px;
	border-style: solid;
	position: absolute;
	top: 0;
}

.progress-circle .progress-circle-left .progress-circle-bar {
	left: 100%;
	border-top-right-radius: 80px;
	border-bottom-right-radius: 80px;
	border-left: 0;
	-webkit-transform-origin: center left;
	transform-origin: center left;
}

.progress-circle .progress-circle-right {
	right: 0;
}

.progress-circle .progress-circle-right .progress-circle-bar {
	left: -100%;
	border-top-left-radius: 80px;
	border-bottom-left-radius: 80px;
	border-right: 0;
	-webkit-transform-origin: center right;
	transform-origin: center right;
}

.progress-circle .progress-circle-value {
	position: absolute;
	top: 0;
	left: 0;
}
