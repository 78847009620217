@include media-breakpoint-down($-app-responsive-mobile-size) {
	.a-action-bar {
		ul {
			li {
				white-space: break-spaces;
				padding: 0 6px;
				a {
					white-space: break-spaces;
				}
			}
		}

		&.vertical {
			ul {
				li {
				}
			}
		}
	}

	.a-action-bar-float {
		bottom: 0px;
		left: 0%;
		transform: translateX(0%);
		width: 100%;

		.a-action-bar {
			transform: translateX(0%);
			max-width: 100%;
			width: 100%;
			overflow: auto;
			border-radius: 0px !important;
			position: relative !important;
		}
	}
}
