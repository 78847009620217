/** Custom breakpoints for large text */
@include media-breakpoint-down(
	custom,
	(
		custom: 1500px,
	)
) {
	.a-input-bar {
		&.xlarge {
			> ul {
				> li {
					li {
						> div {
							flex-wrap: wrap !important;
						}
					}
					label.ilabel {
						width: 100%;
						text-align: left;
						margin-bottom: 6px;
					}
				}
			}
		}
	}
}

/** Tablet */
@include media-breakpoint-down($-app-responsive-tablet-size) {
	.a-input-bar {
		> ul {
			> li {
				padding: 17px 16px;

				li {
					padding: 0px 10px !important;
				}

				label.ilabel {
					width: 100%;
					text-align: left;
					margin-bottom: 6px;

					br {
						content: '';
						&:after {
							content: ' '; // note we've added space here.
						}
					}
				}
			}
		}
	}
}

/** Mobile */
@include media-breakpoint-down($-app-responsive-mobile-size) {
	.a-input-bar {
		&.multiple {
			> ul {
				> li {
					&:not(:last-child) {
						padding-bottom: 0px;
					}
					&:not(:first-child) {
						padding-top: 0px;
					}
				}
			}
		}
		> ul {
			> li {
				width: 100%;

				label.ilabel {
					width: 100%;
				}

				ul {
					flex-wrap: wrap;
					li {
						width: 50%;
						margin-bottom: 10px;

						> div > .ilabel,
						> div > div {
							width: 100%;
						}

						&.single-link {
							width: 100%;
						}

						@include media-breakpoint-down(xsm) {
							width: 100%;
						}
					}
				}
			}
		}

		&.toggle-input {
			> ul {
				> li {
					&:first-child {
						padding-bottom: 8px;
					}
					&:last-child {
						padding-top: 2px;
					}
				}
			}
		}
	}
}
