/*********************************************
	React Datepicker
	@link https://reactdatepicker.com/
 ********************************************/
.react-datepicker-popper {
	z-index: $z-index-4 !important;
}

.react-datepicker-wrapper:not(.no-icon) {
	.react-datepicker__input-container {
		input {
			padding-right: 40px;
		}

		&::after {
			content: '\EB20';
			font-family: 'remixicon' !important;
			font-style: normal;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			position: absolute;
			right: 14px;
			top: 50%;
			font-size: 20px;
			transform: translateY(-50%);
		}
	}
}

.react-datepicker {
	font-family: $-font-body;

	* {
		font-family: $-font-body;
	}

	.react-datepicker__header {
		background-color: white;
		border-bottom-color: $-color-beige;
	}

	.react-datepicker__triangle {
		transform: translate3d(150px, 0px, 0px) !important;
	}

	.react-datepicker__triangle::after {
		border-bottom-color: white !important;
	}

	.react-datepicker__day-name,
	.react-datepicker__day {
		font-size: 14px;
		font-weight: 600;
		font-family: $-font-body;
		line-height: 32px;
		width: 32px;

		&:not(.react-datepicker__day--selected)
			:not(.react-datepicker__day--keyboard-selected)
			:not(.react-datepicker__day--outside-month) {
			color: $-color-primary-ash;
		}
	}

	.react-datepicker__day {
		&,
		&:hover {
			border-radius: 150px;
		}

		&.react-datepicker__day--outside-month {
			color: rgba($-color-secondary-ash, 0.6);
		}
	}

	.react-datepicker__day--keyboard-selected,
	.react-datepicker__day--selected {
		&,
		&:hover,
		&:active {
			background-color: $-color-primary-green;
		}
	}

	.react-datepicker__navigation-icon {
		height: 0;
		width: 0;
		top: 0;

		&::before {
			border-width: 6px;
			top: -7px;
		}
	}

	.react-datepicker__navigation:hover
		.react-datepicker__navigation-icon--previous::before,
	.react-datepicker__navigation-icon--previous::before {
		transform: none;
		right: 0;
		border-color: transparent #000 transparent transparent;
	}

	.react-datepicker__navigation:hover
		.react-datepicker__navigation-icon--next::before,
	.react-datepicker__navigation-icon--next::before {
		transform: none;
		left: 0;
		border-color: transparent transparent transparent #000;
	}
}

/** Inline Style */
.inline-react-datepicker {
	.react-datepicker {
		border: 0;

		.react-datepicker__header {
			background-color: transparent;
			border: 0;
		}

		.react-datepicker__day-names {
			margin-top: 20px;
		}
	}
}

.form-field > .react-datepicker-wrapper {
	display: block;
}

.react-select-header {
	.react-datepicker__current-month {
		display: none;
	}
	.react-datepicker__header__dropdown {
		font-weight: 700;
		font-size: 15px;
	}

	.react-datepicker__month-read-view {
		margin-right: 10px;
	}

	.react-datepicker__month-read-view,
	.react-datepicker__year-read-view {
		visibility: visible !important;
	}

	.react-datepicker__year-read-view--down-arrow,
	.react-datepicker__month-read-view--down-arrow {
		right: -9px;
		top: 1px;
		border: 0px;
		transform: rotate(0deg);

		&::after {
			font-family: 'remixicon';
			content: '\EA4D';
			font-size: 13px;
		}
	}

	.react-datepicker__month-dropdown,
	.react-datepicker__year-dropdown {
		background-color: white;
	}

	.react-datepicker__navigation--years-upcoming,
	.react-datepicker__navigation--years-previous {
		position: relative;
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: center 0px;
		height: 24px;
	}

	.react-datepicker__navigation--years-upcoming {
		background-image: url('./../../images/arrow-up-s-fill.svg');
	}

	.react-datepicker__navigation--years-previous {
		background-image: url('./../../images/arrow-down-s-fill.svg');
		background-position: center -10px;
	}

	.react-datepicker__year-option,
	.react-datepicker__month-option,
	.react-datepicker__month-year-option {
		font-weight: 600;
	}
}

/*********************************************
	This section is for other layout.
 ********************************************/
.tasks-list {
	.alert {
		--bs-alert-padding-x: 30px;
		--bs-alert-color: #{$-color-charcoal};
		cursor: pointer;

		@extend .left-border-sand;
		transition: all 0.35s ease;

		&:before {
			transition: all 0.35s ease;
		}

		&.active,
		&:hover {
			@extend .left-border-primary;
			@extend .bg-secondary;
			color: $-color-charcoal;
		}
	}
}
