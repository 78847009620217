/**********************************
	Bootstrap Override
	Place any boostrap related here.
 *********************************/

@media (min-width: 1400px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl,
	.container-xxl {
		max-width: 1640px;
	}
}

@include media-breakpoint-down(lg) {
	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl,
	.container-xxl {
		max-width: 100%;
	}
}

/** LINE HEIGHT */
@for $i from 10 through 50 {
	.lh-#{$i}px {
		line-height: #{$i}px;
	}
}

/** WIDTH */
@for $i from 10 through 300 {
	@if ($i % 10 == 0) {
		// 20px, 40px, 60px, 80px...
		.w-#{$i}px {
			width: #{$i}px;
		}
		.mw-#{$i}px {
			min-width: #{$i}px;
		}
	}
}

$add-width: 30 33 40 60 70 80 90;
@each $w in $add-width {
	.w-#{$w} {
		@if $w == 33 {
			width: 33.33% !important;
		} @else {
			width: #{$w + '%'} !important;
		}
	}
}

/** BACKGROUND COLOR -------****/
@each $name, $c in $-colors {
	.bg-#{$name} {
		background-color: $c !important;

		@if $name == 'secondary' {
			color: $-color-secondary-green;
		}

		@if contains($-colors-gadient, $name) {
			&.gradient {
				background: map-get($-colors-gadient, $name);
			}
		}
	}
}

/** BORDER -------****/
.border-1 {
	border-width: 1px;
	border-style: solid;
}

@each $name, $c in $-colors {
	.border-#{$name} {
		border-color: $c !important;
	}
}

/** Dot on Left */
@each $name, $c in $-colors {
	.dot-#{$name} {
		position: relative;
		padding-left: 16px;
		white-space: nowrap;

		&::before {
			content: ' ';
			width: 12px;
			height: 12px;
			background-color: $c;
			border-radius: 120px;
			position: absolute;
			top: 3px;
			left: 0px;
		}
	}
}

.dot {
	position: relative;
	padding-left: 16px;
	white-space: nowrap;

	&::before {
		content: ' ';
		width: 12px;
		height: 12px;
		background-color: var(--color);
		border-radius: 120px;
		position: absolute;
		top: 3px;
		left: 0px;
	}
}

/* Add custom border on left */
@each $name, $c in $-colors {
	.left-border-#{$name} {
		&::before {
			position: absolute;
			content: ' ';
			left: 0;
			top: 0;
			width: 6px;
			height: 100%;
			background-color: $c;
			border-radius: 20px;
		}
	}
}

/** BORDER RADIUS -------****/
@each $br in $-border-radius-range {
	.roundedx-#{$br} {
		border-radius: #{$br}px !important;
	}
}

/** TEXT COLORS -------****/
@each $name, $c in $-colors {
	.text-#{$name} {
		color: $c !important;
	}
	.text-hover-#{$name} {
		&:hover {
			color: $c !important;
		}
	}
}

/** Z-INDEX -------****/
@each $zi in $z-index-list {
	.zi-#{$zi} {
		z-index: $zi !important;
	}
}

/** VISIBILITY -------****/
.hidden {
	visibility: hidden;
	opacity: 0;
}

/** ANIMATION -------****/
.animate-all-3s {
	transition: all 0.3s ease-in-out;
}

/** ALERT -------****/
@each $name, $c in $-colors {
	.alert-#{$name} {
		--bs-alert-bg: #{$c};
	}
}

/** NAVBAR ****************/
.navbar-brand {
	small {
		font-size: 12px;
		position: absolute;
	}
}

/** NAVBAR ****************/
.breadcrumb {
	.breadcrumb-item {
		padding-left: 0px;
		padding-right: 6px;

		&::before {
			padding-left: 0px;
			padding-right: 4px;
		}

		&.active {
			color: #fff;
		}
	}
}

/** BUTTONS ****************/
.btn-icon {
	display: flex;
	justify-content: center;
	align-items: center;

	[class^='ri-'] {
		margin-right: 2px;
	}
}

.btn {
	// Medium or normal
	--bs-btn-border-radius: 4px;
	--bs-btn-font-size: 14px;
	--bs-btn-padding-y: 8px;

	min-width: 92px;
	min-height: 40px;

	text-decoration: none;
	&:hover,
	&:focus,
	&:active {
		text-decoration: none;
	}

	&.square {
		--bs-btn-padding-x: 5px;
		min-width: 40px;
		min-height: 40px;
		max-height: 40px;
	}

	&.btn-action {
		border-color: $-color-sand;

		&:hover,
		&:active,
		&:focus {
			border-color: $-color-secondary-sand;
		}
	}

	// Small
	&.btn-sm {
		--bs-btn-padding-y: 3px;
		--bs-btn-padding-x: 12px;

		min-width: 84px;
		min-height: 32px;

		&.square {
			--bs-btn-padding-x: 5px;
			min-width: 32px;
			min-height: 32px;
			max-height: 32px;
		}
	}

	// Large
	&.btn-lg {
		--bs-btn-padding-y: 12px;
		--bs-btn-padding-x: 26px;

		min-width: 108px;
		min-height: 48px;

		&.square {
			--bs-btn-padding-x: 5px;
			min-width: 32px;
			min-height: 32px;
		}
	}

	&.btn-primary {
		--bs-btn-color: #ffffff;
		--bs-btn-bg: #008b6d;
		--bs-btn-border-color: #008b6d;
		--bs-btn-hover-color: #ffffff;
		--bs-btn-hover-bg: #008b6d;
		--bs-btn-hover-border-color: #008b6d;
		--bs-btn-focus-shadow-rgb: 60, 153, 110;
		--bs-btn-active-color: #ffffff;
		--bs-btn-active-bg: #006a53;
		--bs-btn-active-border-color: #006a53;
		--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
		--bs-btn-disabled-color: #7c7c7c;
		--bs-btn-disabled-bg: #dedcd7;
		--bs-btn-disabled-border-color: #dedcd7;
	}

	&.btn-secondary {
		--bs-btn-color: #006a53;
		--bs-btn-bg: #e7faf3;
		--bs-btn-border-color: #e7faf3;
		--bs-btn-hover-color: #006a53;
		--bs-btn-hover-bg: #e7faf3;
		--bs-btn-hover-border-color: #e7faf3;
		--bs-btn-focus-shadow-rgb: 60, 153, 110;
		--bs-btn-active-color: #006a53;
		--bs-btn-active-bg: #d6f4e9;
		--bs-btn-active-border-color: #d6f4e9;
		--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
		--bs-btn-disabled-color: #7c7c7c;
		--bs-btn-disabled-bg: #dedcd7;
		--bs-btn-disabled-border-color: #dedcd7;
	}

	&.btn-light {
		--bs-btn-color: #211f21;
		--bs-btn-bg: #ffffff;
		--bs-btn-border-color: #dedcd7;
		--bs-btn-hover-color: #211f21;
		--bs-btn-hover-bg: #ffffff;
		--bs-btn-hover-border-color: #dedcd7;
		--bs-btn-focus-shadow-rgb: 60, 153, 110;
		--bs-btn-active-color: #006a53;
		--bs-btn-active-bg: #d6f4e9;
		--bs-btn-active-border-color: #d6f4e9;
		--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
		--bs-btn-disabled-color: #7c7c7c;
		--bs-btn-disabled-bg: #dedcd7;
		--bs-btn-disabled-border-color: #dedcd7;
	}

	&.btn-ivory {
		--bs-btn-border-color: #eae8e5;
	}

	&.btn-ivory-light {
		--bs-btn-border-color: #eae8e5;
		--bs-btn-bg: #eeeeee;
	}

	&.btn-trans-light {
		--bs-btn-color: #211f21;
		--bs-btn-bg: transparent;
		--bs-btn-border-color: #dedcd7;
		--bs-btn-hover-color: #211f21;
		--bs-btn-hover-bg: #ffffff;
		--bs-btn-hover-border-color: #dedcd7;
		--bs-btn-focus-shadow-rgb: 60, 153, 110;
		--bs-btn-active-color: #006a53;
		--bs-btn-active-bg: #d6f4e9;
		--bs-btn-active-border-color: #d6f4e9;
		--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
		--bs-btn-disabled-color: #7c7c7c;
		--bs-btn-disabled-bg: #dedcd7;
		--bs-btn-disabled-border-color: #dedcd7;
	}

	&.btn-link {
		text-decoration: none;
	}

	&.no-min-height {
		min-height: 1px;
	}

	&.no-outline {
		&,
		&:focus,
		&:hover,
		&:active {
			border: 0;
			outline: none;
			box-shadow: none;
		}
	}

	&:disabled,
	&.disabled,
	&[disabled] {
		opacity: 0.55;
		cursor: default;
	}
}

/** FORM CONTROLS *******************/
.form-control {
	&.file-upload-white {
		color: white;

		&.has-file {
			color: $-color-dark-primary;
		}

		&::-webkit-file-upload-button {
			display: none;
		}

		&::file-selector-button {
			display: none;
		}
	}
}
.form-control,
.form-select {
	padding-top: 6px;
	padding-bottom: 6px;
	min-height: 41px;

	&:focus {
		box-shadow: 0 0 0 0.25rem rgba($color: #176de5, $alpha: 0.25);
		border-color: #176de5;
	}
}

.form-control-lg,
.form-select-lg {
	padding-top: 11px;
	padding-bottom: 11px;
	min-height: 48px;
}

.form-control-sm,
.form-select-sm {
	padding-top: 3px;
	padding-bottom: 3px;
	font-size: 12px;
	min-height: 32px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

/** INPUT GROUP *******************/
.input-group {
	&.blend {
		> * {
			background-color: white;
			margin-bottom: 0px;

			&:first-child {
				border-right-width: 0px;
			}

			&:last-child {
				border-left-width: 0px;
			}
		}

		&.left {
			> * {
				&:first-child {
				}

				&:last-child {
					padding-left: 0px;
				}
			}
		}
	}
}

/*Custom input to have number type fields without the spinners */
/* For Firefox */
.no-spinner[type='number'] {
	-moz-appearance: textfield;
}

/* For Chrome, Safari, Edge, Opera */
.no-spinner[type='number']::-webkit-inner-spin-button,
.no-spinner[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/** -------- */
.col-form-label {
	line-height: 1.2;
}

label,
.form-control,
.form-select {
	font-size: 14px;
}

.form-floating {
	label {
		opacity: 0.65;
	}

	> .form-control {
		height: 40px;
		min-height: 40px;
		padding: 8px 0.75rem;

		& ~ label {
			padding: 10px 0.75rem;
		}

		&.form-control-lg {
			height: 48px;
			min-height: 48px;

			& ~ label {
				padding: 0.875rem 0.75rem;
			}
		}
	}
}

.form-group-extra {
	display: flex;
	align-items: center;
	position: relative;

	.form-control {
		padding-right: 20px;
	}

	span,
	label {
		position: absolute;
		right: 12px;
		color: $-color-placeholder;
		margin-top: 7.5px;
	}

	&.left,
	&.dollar,
	&.reversed {
		.form-control {
			padding-left: 25px;
			padding-right: 12px;
		}

		span,
		label {
			position: absolute;
			left: 12px;
			margin-top: 0;
			color: $-color-placeholder;
		}
	}

	&.dollar {
		&::before {
			content: '$';
			position: absolute;
			left: 12px;
			margin-top: -1px;
			color: $-color-primary-ash;
		}

		&.right {
			&::before {
				left: unset;
				right: 12px;
			}

			.form-control {
				padding-left: 12px;
				padding-right: 20px;
			}
		}
	}

	/** add custom after this if text is longer... */
}

/*********************************************
	React Select
	@link https://react-select.com/home
 ********************************************/

.react-select {
	[class$='-control'] {
		min-height: 41px;
	}

	.css-1pahdxg-control {
		box-shadow: 0 0 0 0.25rem rgb(23 109 229 / 25%);
	}

	.css-1okebmr-indicatorSeparator {
		width: 0px;
	}

	.css-tlfecz-indicatorContainer {
		color: $-color-placeholder;
	}

	[class$='-menu'] {
		margin-top: 6px;
	}

	&-lg {
		[class$='-control'] {
			min-height: 48px;
		}
	}

	&-sm {
		[class$='-control'] {
			min-height: 33px;
			height: 33px;

			> div {
				margin-top: -2px;
			}
		}
	}

	&-full-width {
		width: 100%;
	}

	&.no-border {
		[class$='-control'] {
			border: 0px !important;
			box-shadow: none !important;
		}
	}

	&.no-shadow {
		[class$='-control'] {
			box-shadow: none !important;
			border-color: #cccccc !important;
		}
	}

	caret-color: transparent;
}

/** FORM RADIO/CHECK *******************/
.form-check {
	margin-bottom: 0px;
	margin-right: 0px;
	min-height: 1px;

	.form-check-input {
		min-width: 18px;
		min-height: 18px;
		position: relative;
		margin-right: 0px;
		margin-top: 0px;

		/** Checkbox */
		&[type='checkbox'] {
			border-radius: 0px;
			background-size: 110% 110%;
		}

		/** Radio */
		&[type='radio']:checked {
			background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23008B6D%27/%3e%3c/svg%3e');
			background-color: white;
			background-size: 130% 130%;
		}

		&[type='radio']:disabled:checked {
			background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23DEDCD7%27/%3e%3c/svg%3e');
			background-color: white;
			background-size: 130% 130%;
		}

		&:focus {
			border-color: $-color-primary-green;
		}

		&:checked {
			border-color: $-color-primary-green;
			background-color: $-color-primary-green;
		}

		&:disabled {
			opacity: 1;
			background-color: $-color-sand !important;
			border-color: $-color-sand !important;

			& ~ .form-check-label,
			& ~ .form-check-label {
				opacity: 1;
			}
		}

		&[type='radio']:disabled:checked {
			background-color: white !important;
		}

		&.is-invalid:focus {
			border-color: $-color-secondary-red;
		}

		&.is-invalid:checked {
			background-color: $-color-secondary-red;
			border-color: $-color-secondary-red;
		}

		&.is-invalid[type='radio']:checked {
			background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23C2432D%27/%3e%3c/svg%3e');
			background-color: white;
		}
	}

	&.line {
		.form-check-input {
			background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' standalone='yes'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' width='96' height='96'%3E%3Cpath style='fill:transparent; stroke:none;' d='M0 0L0 96L96 96L96 0L0 0z'/%3E%3Cpath style='fill:%23ffffff; stroke:none;' d='M9.44907 45.0278C5.91855 47.557 8.7669 51.3071 12.044 51.8519C29.6753 54.7825 50.1077 52 68 52C72.4828 52 83.9658 54.0487 87.2137 50.3974C93.4154 43.4254 76.6684 44 74 44L27 44C22.4487 44 13.3021 42.2676 9.44907 45.0278z'/%3E%3C/svg%3E%0A");
			background-size: 90% 130%;
		}
	}

	.form-check-label {
		position: relative;
		margin-left: 8px;
		padding-top: 0px;

		&:empty {
			margin-left: 0px;
		}
	}
	&-inline {
		display: inline-flex;
		align-items: center;

		&.vtop {
			align-items: flex-start;
		}
	}

	/** Circular checkbox */
	&.circle-check {
		.form-check-input {
			&[type='checkbox'] {
				border-radius: 150px !important;
			}
		}
	}
}

/** DROPDOWNS ********************/
.dropdown {
	&-toggle {
		padding: 0 20px;
		font-weight: bold;
		border-color: $-color-sand;
		&:after {
			display: inline-block;
			margin-left: 15px;
			vertical-align: 0.255em;
			content: '';
			border-top: 0.2em solid;
			border-right: 0.2em solid transparent;
			border-bottom: 0;
			border-left: 0.2em solid transparent;
		}

		&[aria-expanded='true'] {
			&:after {
				transform: rotate(180deg);
			}
		}

		&.btn-ivory {
			&:hover,
			&[aria-expanded='true'] {
				border-color: $-color-sand;
				background-color: $-color-sand;
			}
		}
	}

	&-menu {
		margin-top: -2.5px;
		background-color: $-color-ivory;
		padding: 5px;
		box-shadow: 0 3px 2px 1px hsla(0, 0%, 0%, 0.1),
			0 4px 11px hsla(0, 0%, 0%, 0.1);
	}

	&-item {
		padding: 7px 10px;
		font-weight: bold;
		display: flex;
		align-items: center;
		font-size: 13px;
		i {
			margin-right: 10px;
			font-size: 14px;
		}
		&:hover,
		&:focus,
		&:active {
			color: inherit;
			background-color: $-color-sand;
		}
	}
}

/** BADGES *******************/
.badge {
	$padding-x: 10px;
	--bs-badge-border-radius: 150px;
	padding: 5px 9px 5px 9px;
	position: relative;
	min-height: 20px;

	.btn-close {
		color: white;
		opacity: 1;
		background: transparent
			url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 25 0 1 0-1.414z'/%3E%3C/svg%3E")
			center/1em auto no-repeat;
		width: 0.5em;
		height: 0.5em;
		outline: none;
		position: absolute;

		&:focus,
		&:active {
			box-shadow: none;
			outline: none;
		}

		span {
			font-weight: 700;
		}
	}

	&.bg-secondary {
		.btn-close {
			background: transparent
				url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23006A53'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 25 0 1 0-1.414z'/%3E%3C/svg%3E")
				center/1em auto no-repeat;
		}
	}

	&.bg-grey {
		.btn-close {
			background: transparent
				url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%237C7C7C'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 25 0 1 0-1.414z'/%3E%3C/svg%3E")
				center/1em auto no-repeat;
		}
	}

	&.bg-light-outline {
		color: #7c7c7c;
		border: 1px solid #dedcd7;

		.btn-close {
			background: transparent
				url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%237C7C7C'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 25 0 1 0-1.414z'/%3E%3C/svg%3E")
				center/1em auto no-repeat;
		}
	}

	&.bg-dark-outline {
		color: #211f21;
		border: 1px solid #006a53;

		.btn-close {
			background: transparent
				url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23211F21'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 25 0 1 0-1.414z'/%3E%3C/svg%3E")
				center/1em auto no-repeat;
		}
	}

	&.left {
		padding: 4px $padding-x 5px 25px;

		.btn-close {
			left: $padding-x - 1px;
		}
	}

	&.right {
		padding: 4px 25px 5px $padding-x;

		.btn-close {
			right: $padding-x - 1px;
		}
	}
}

/** PROGRESS BAR *******************/
.progress {
	--bs-progress-height: 10px;
	--bs-progress-font-size: 0.75rem;
	--bs-progress-bg: #e7faf3;
	--bs-progress-border-radius: 0.375rem;
	--bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
	--bs-progress-bar-color: #fff;
	--bs-progress-bar-bg: #0d6efd;
	--bs-progress-bar-transition: width 0.6s ease;

	&:not(.primary) {
		--bs-progress-bg: #e9ecef;
	}
}

.progress-bar {
	&.bg-dark-grey {
		--bs-progress-bar-bg: #7c7c7c;
	}
}

/** BREADCRUMBS *******************/
.breadcrumb {
	.breadcrumb-item {
		& + .breadcrumb-item::before {
			content: '\EA6E';
			font-family: remixicon !important;
			font-weight: bold;
			color: $-dark-color;
			font-size: 14px;
		}

		a {
			text-decoration: none;
			color: $-dark-color;
			font-weight: 500;
		}
	}

	> .breadcrumb-item {
		&:not(:last-child) {
			a {
				opacity: 0.5;
				&:hover {
					text-decoration: underline;
				}
			}
		}

		&:last-child {
			a {
				font-weight: bold;
			}
		}
	}

	&-light {
		.breadcrumb-item {
			a {
				color: #fff;
			}
			& + .breadcrumb-item::before {
				color: #fff;
			}
		}
	}
}

/** ACCORDION *******************/
.accordion {
	--bs-accordion-border-width: 0px;

	.accordion-header {
		--bs-accordion-active-bg: #{$-color-beige};
		--bs-accordion-btn-bg: #{$-color-beige};
		--bs-accordion-border-color: #{$-color-beige};
		--bs-accordion-active-color: #{$-color-dark-primary};
		--bs-accordion-btn-active-icon: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000000%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e');

		.accordion-button {
			--bs-accordion-btn-icon-width: 16px;
			--bs-accordion-btn-icon-height: 16px;
			font-family: $-font-body;

			&.collapsed::after {
				transform: rotate(180deg);
			}
			&:not(.collapsed)::after {
				transform: rotate(360deg);
			}
			&:focus {
				box-shadow: none;
			}
		}
	}
}

/*********************************************
	Other styles
 ********************************************/
.wrap-after {
	position: relative;

	&::after {
		content: ' ';
		position: absolute;
		z-index: 20;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.modal .modal-content {
	box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.modal {
	.spinner-div {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.5);
		z-index: 99;

		> div,
		> div > div {
			height: 100% !important;
		}
	}

	&.fixed-footer {
		.modal-body {
			padding-right: 0px !important;
			max-height: calc(100vh - 100px);
			overflow: auto;
		}

		.modal-footer {
			box-shadow: 0px -2px 10px 1px #e4e4e4;
			z-index: 20;
			border-top: 1px solid #dfdfdf;
		}
	}
}

/** Date picker */
/** override index */
.rs-picker-daterange-menu {
	z-index: 10001;
}

.rs-picker-menu .rs-btn-primary {
	@extend .btn;
	border: 1px solid #198754 !important;
	border-radius: var(--bs-btn-border-radius) !important;
	background-color: #198754 !important;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.rs-picker-menu
	.rs-calendar-table-cell-selected
	.rs-calendar-table-cell-content {
	background-color: #198754 !important;
}
.rs-picker-menu
	.rs-calendar
	.rs-calendar-table-cell:hover
	.rs-calendar-table-cell-content {
	color: #bfdccf !important;
}

.rs-picker-menu
	.rs-calendar-table-cell-is-today
	.rs-calendar-table-cell-content {
	box-shadow: inset 0 0 0 1px #198754;
}

/** Budget Filter Override **/
.budget-filter {
	&.a-input-bar > ul > li * {
		font-size: 14px;
	}
}

/** Transaction Search Fields Override **/
.ff-transaction-search {
	.no-border {
		border: none;

		input {
			border: none;
			&:focus {
				box-shadow: none !important;
				outline: none !important;
			}
		}
		.rs-picker-toggle {
			border: none;
			padding-top: 5px;
			padding-bottom: 5px;
			box-shadow: none !important;
			outline: none !important;
			border: 0px !important;

			.rs-picker-toggle-caret,
			.rs-picker-toggle-clean {
				top: 4px;
			}
		}
	}

	input {
		&:focus {
			box-shadow: none !important;
			outline: none !important;
		}
	}
}
.input-no-arrows {
	-moz-appearance: textfield;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}
.daterange-labeled-group {
	.group-label {
		color: #7d7d7d;
		top: -6px;
		left: 10px;
		z-index: 6;
		font-size: 11.5px;
		background: white;
		font-weight: bold;
	}
}
.text-disabled {
	color: var(--bs-secondary-bg);
	opacity: 1;
}

.wys-disabled-custom > * {
	color: rgb(84, 84, 84) !important;
}
