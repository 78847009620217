/** Mobile */
@include media-breakpoint-down($-app-responsive-mobile-size) {
	.a-clipper-media {
		.a-clipper-sm {
			> div {
				width: 33.33%;
				flex: 0 1 33.33%;
			}
		}

		.a-clipper-lg {
			> div {
				width: 100%;
				flex: 0 1 100%;
			}
		}

		.a-clipper-md {
			> div {
				width: 50%;
				flex: 0 1 50%;
			}
		}

		.a-clipper-media-item {
			&-handle {
				&.active,
				&:hover {
					h6 {
						&:after {
							margin-left: 4px;
						}
					}
				}
			}
		}
	}
}
