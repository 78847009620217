@include media-breakpoint-down($-app-responsive-mobile-size) {
	.btn {
		--bs-btn-padding-y: 2px;
		--bs-btn-padding-x: 10px;

		min-height: 32px;
		min-width: 80px;
		line-height: 2;

		&.btn-lg {
			--bs-btn-padding-y: 4px;
			--bs-btn-padding-x: 12px;
			min-height: 40px;
			min-width: 90px;
		}

		&.btn-sm {
			--bs-btn-padding-y: 1px;
			--bs-btn-padding-x: 8px;
			min-height: 26px;
			min-width: 70px;
		}
	}
}
