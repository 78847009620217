.invalid-instance-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	flex-direction: column;
}

.invalid-instance-text {
	font-size: 1.2rem;
	margin: 0;
}

.invalid-instance-link-container {
	text-wrap: wrap;
	word-break: break-all;
}

.invalid-instance-link {
	padding-left: 0.4rem;
}
