.error-layout {
	&::before {
		content: ' ';
		position: absolute;
		width: 100%;
		height: 100%;
		background-image: url('../../images/bg-error.png');
		z-index: -1;
		opacity: 0.1;
		filter: blur(4px);
		left: 0;
		top: 0;
	}
}
