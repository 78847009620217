@include media-breakpoint-down($-app-responsive-mobile-size) {
	.a-header {
		padding: $-app-content-padding-mobile $-app-content-padding-mobile 15px;

		.a-header-title {
			flex-wrap: wrap;
		}

		.a-header-buttons {
			height: 35px;

			li {
				a {
					line-height: 26px;
				}
			}
		}

		.a-header-cta {
			@include media-breakpoint-down(md) {
				// width: 100%;
				margin-bottom: 10px;
			}
		}
	}
}
