/** Mobile */
@include media-breakpoint-down($-app-responsive-mobile-size) {
	.a-floating-help {
		right: 15px;

		.btn {
			width: 60px;
			height: 60px;
			font-size: 18px;
		}
	}

	.app-contains-action-bar {
		.a-floating-help {
			bottom: 11%;
		}
	}

	.app-contains-form {
		&.app-scrolled-bottom {
			.a-floating-help {
				bottom: 14%;
			}
		}
	}
}
