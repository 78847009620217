/** Simple box */
.a-box {
	$--sizes: (
		'two': '50%',
		'three': '33.33%',
		'four': '25%',
		'five': '20%',
	);
	$--margin: 15px;

	margin-left: -($--margin);
	margin-right: -($--margin);

	> div {
		min-width: 0;
		max-width: 100%;
		width: 100%;
		padding: 0 $--margin;

		@include media-breakpoint-down(lg) {
			width: 100% !important;

			&:not(&:last-child) {
				margin-bottom: 30px;
			}
		}
	}

	.a-box-basis:not(.no-padding) {
		padding: 36px;
	}

	&.full {
		flex-wrap: wrap;
	}

	@each $s, $w in $--sizes {
		&.#{$s} {
			> div {
				width: #{$w};
			}
		}
	}

	.a-box-cards {
		$--margin: 15px;

		margin-left: -($--margin);
		margin-right: -($--margin);
		margin-top: -($--margin * 2);

		> .a-box-cards-basis {
			min-width: 0;
			padding: 0 $--margin;
			max-width: 100%;
			width: 100%;
			padding-top: $--margin * 2;
		}

		@each $s, $w in $--sizes {
			&.#{$s} {
				> .a-box-cards-basis {
					width: #{$w};
				}
			}
		}

		> .a-box-cards-basis {
			@include media-breakpoint-down(lg) {
				width: 100% !important;
			}

			@include media-breakpoint-down(sm) {
				width: 100% !important;
			}

			&.full {
				width: 100% !important;
			}
		}
	}

	.card-title {
		small {
			border-radius: 11px;
			position: relative;
			top: -4px;
		}

		.currency {
			position: relative;

			small {
				position: absolute;
				top: 2px;
				margin-left: -8px;
			}
		}

		.separator-two {
			position: absolute;
			width: 100%;
			border-top: 1px solid $-color-sand;
			top: 140%;
		}
	}

	.card-text {
		position: relative;

		.card-action {
			display: block;
			position: absolute;
			bottom: 0;
			text-align: center;
			padding: 10px;
			width: 100%;
			border-radius: 4px;
			text-decoration: none;
			font-weight: bold;

			i {
				position: relative;
				top: 2px;
			}
		}
	}

	.card-type-2 {
		.card-text {
			min-height: 160px;
			padding-bottom: 75px;

			@include media-breakpoint-down(lg) {
				min-height: 140px;
			}
		}
	}

	.card-type-3 {
		a {
			transition: all 0.4s;
			&:hover {
				box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
			}
		}
	}
}

.tabular-title {
	> * {
		flex: 1 1 auto !important;
		z-index: 8;

		&:last-child {
			> span {
				padding-left: 20px;
			}
		}
		&:first-child {
			> span {
				padding-right: 20px;
			}
		}
	}

	.separator {
		border-top: 1px dashed $-color-sand;
		width: auto;
		position: absolute;
		width: 100%;
		top: 80%;
		z-index: 5;
	}
}

.pr-0 {
	padding-right: 0 !important;
}
