.super-header .breadcrumb-light .breadcrumb,
.super-header .breadcrumb-dark-green .breadcrumb {
	margin-bottom: 0;
}

.super-header .back-button {
	min-height: auto;
	min-width: auto;
	padding: 0;
}
