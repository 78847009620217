.rs-picker-menu {
	z-index: 201;
	border-radius: 0;

	.rs-picker-daterange-predefined {
		height: 0;
	}

	.rs-stack {
		& > .rs-stack-item {
			&:nth-child(2) {
				display: flex;
				flex-direction: row-reverse;
				.rs-picker-toolbar-ranges {
					display: flex;
					flex-direction: column;
				}
			}
		}

		.rs-picker-toolbar {
			display: flex;
			flex-direction: column;
		}
	}

	.rs-stack-item {
		button {
			padding-left: 0;
			padding-right: 0;
		}
	}

	button {
		color: #000 !important;
	}

	.rs-btn-primary {
		background-color: #0088cc;
		--bs-btn-padding-y: 3px;
		--bs-btn-padding-x: 12px;
		min-width: 70px;
		min-height: 32px;
		color: #fff !important;
	}

	.rs-calendar-table-cell-content {
		font-size: 12px;
	}

	.rs-btn-sm {
		font-size: 12px;
	}

	.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
		background-color: #0088cc;
	}
	.rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
		color: #0088cc;
	}
	.rs-calendar-table-cell-in-range:before {
		background-color: #eaf4f7;
	}

	.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
		box-shadow: inset 0 0 0 1px #0088cc;
	}

	.rs-calendar-header-title,
	.rs-calendar-table-header-row .rs-calendar-table-cell-content {
		font-weight: 600;
		color: #000;
	}
}

.rs-picker-daterange-menu {
	z-index: 10001;
}

.cr-fiscalmonth-datepicker {
	width: 150px;
}
