@import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

.rdw-editor-wrapper {
	border: 1px solid #dedcd7;
	border-radius: 4px;
	padding: 10px;

	.rdw-editor-toolbar {
		border-color: #eeede9;
		background-color: #eeede9;
		padding: 3px 5px;
		align-items: center;

		.rdw-inline-wrapper,
		.rdw-colorpicker-wrapper {
			padding: 3px 0;
			margin-bottom: 0;
			.rdw-option-wrapper {
				background: transparent;
				border: 1px solid transparent;
				height: 26px;
				margin-bottom: 0;
				&:hover,
				&:focus {
					border-color: #dedcd7;
					box-shadow: none;
				}
				img {
					max-width: 100%;
				}
			}
		}

		.rdw-colorpicker-modal {
			left: -10px;
			width: 200px;
			.rdw-colorpicker-modal-style-label {
				font-size: 14px;
			}
			.rdw-colorpicker-modal-style-label-active {
				border-bottom: none;
				font-weight: bold;
			}
			.rdw-colorpicker-option {
				height: 22px;
			}
		}
	}

	.rdw-editor-main {
		height: 150px;
	}
}

.stock-item .demo-editor {
	height: 299px;
}
