$border-color: #ebeff2;
$border-width: 1px;

.gantt-task-list-table-body {
	table-layout: fixed;

	td {
		border-bottom: $border-color $border-width solid;
		border-left: $border-color $border-width solid;
		border-right: $border-color $border-width solid;
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.ganttTaskListTableHeader {
	table-layout: fixed;

	th {
		border-left: $border-color $border-width solid;
		border-right: $border-color $border-width solid;
		border-top: $border-color $border-width solid;
		border-bottom: $border-color $border-width solid;
	}
}

g.rows > rect:nth-child(even) {
	fill: #fff;
}

g.rowLines > line {
	stroke: $border-color;
	stroke-width: $border-width;
}

g.ticks > line {
	stroke: $border-color;
	stroke-width: $border-width;
}

g.calendar > rect {
	stroke: $border-color;
	stroke-width: $border-width;
}
