.stock-items-grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 10px;
	margin-bottom: 1rem;

	@include media-breakpoint-up(sm) {
		grid-template-columns: repeat(3, 1fr);
	}

	@include media-breakpoint-up(lg) {
		grid-template-columns: repeat(min(4, var(--columns)), 1fr);
	}

	@include media-breakpoint-up(xl) {
		grid-template-columns: repeat(var(--columns), 1fr);
	}
}
