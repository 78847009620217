@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
	.tw-scrollbar-hide::-webkit-scrollbar {
		scrollbar-width: none !important; /* Firefox */
	}

	.tw-scrollbar-hide {
		-ms-overflow-style: none !important; /* IE and Edge */
		scrollbar-width: none !important; /* Firefox */
	}
}
