.budget-banner {
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	justify-content: center;
	gap: 16px;
	padding: 8px 24px;
	color: #707070;
	border-width: 1px;
	border-style: solid;
}

.budget-banner--error {
	border-color: #e6b9be;
	background-color: #f7c4ca;
}

.budget-banner--warning {
	border-color: #e8e7c9;
	background-color: #faf9d5;
}

.budget-banner--message {
	font-weight: 600;
	margin: 0;
	padding: 0;
}

.budget-banner--icon {
	font-size: 24px;
	color: #7d7d7d;
}
