/** Mobile */
@include media-breakpoint-down($-app-responsive-mobile-size) {
	body {
		--bs-body-font-size: 14px;
	}

	h1 {
		font-size: 42px;
	}

	h2 {
		font-size: 32px;
	}

	h3 {
		font-size: 22px;
	}

	h4 {
		font-size: 20px;
	}

	h5 {
		font-size: 18px;
	}

	h6 {
		font-size: 15px;
	}

	/** FONTS *****/

	@each $name, $px in $-font-sizes {
		.fs-#{$name} {
			@if $px > 0.875rem {
				font-size: ($px - 0.125rem) !important;
			} @else {
				font-size: $px !important;
			}
		}
	}

	/**********************************
        Main Contents
    *********************************/
	.main-content {
		padding-top: $-app-topbar-height-mobile;

		.content-padding {
			padding: 20px $-app-content-padding-mobile;
		}

		.has-action-bar {
			padding-bottom: 85px;
		}
	}

	.impersonating {
		padding-top: 100px !important;
	}

	.app-contains-action-bar {
		.content-padding {
			padding-bottom: 85px;
		}
	}
}

@import './uikit/responsive/override-responsive.scss';
@import './uikit/responsive/sidebar-menu-responsive.scss';
@import './uikit/responsive/headers-responsive.scss';
@import './uikit/responsive/floating-help-responsive.scss';
@import './uikit/responsive/list-filter-responsive.scss';
@import './uikit/responsive/action-bar-responsive.scss';
@import './uikit/responsive/table-responsive.scss';
@import './uikit/responsive/footer-responsive.scss';
@import './uikit/responsive/switch-responsive.scss';
@import './uikit/responsive/box-responsive.scss';
@import './uikit/responsive/input-bar-responsive.scss';
@import './uikit/responsive/clipper-responsive.scss';
