/** Mixins */
@mixin list-custom-responsive() {
	.filters {
		width: 100%;
		> div {
			min-width: 230px;

			@include media-breakpoint-down(
				x,
				(
					'x': 1350px,
				)
			) {
				min-width: 33.33%;
			}
		}

		.a-mini-zoomer {
			min-width: 100%;
			max-width: 100%;

			@include media-breakpoint-down(
				x,
				(
					'x': 1440px,
				)
			) {
				min-width: 60%;
			}

			@include media-breakpoint-down(
				x,
				(
					'x': 425px,
				)
			) {
				min-width: 90px;
			}
		}
	}

	.filter-separator {
		display: block;
		width: 100%;
		margin: 15px 0;

		&::after {
			content: ' ';
			position: absolute;
			width: 100%;
			height: 1px;
			left: 0px;
			background-color: $-color-sand;
		}
	}

	.actions {
		width: 100%;
	}
}

@mixin list-custom-responsive-no-zoomer() {
	.filters {
		width: 100%;
	}

	.filter-separator {
		display: block;
		width: 100%;
		margin: 15px 0;

		&::after {
			content: ' ';
			position: absolute;
			width: 100%;
			height: 1px;
			left: 0px;
			background-color: $-color-sand;
		}
	}

	.actions {
		width: 100%;
	}
}
/** Mixins */

@include media-breakpoint-down($-app-responsive-mobile-size) {
	.a-filter {
		padding: 8px 8px 12px;

		.filter-expand {
			display: block;
			.btn {
				min-height: 1px;
				padding: 0px;
			}

			i {
				transition: all 0.25s;
			}
		}

		.form-label {
			min-width: 60px;
		}

		.form-group-extra,
		.rs-picker,
		.react-select-header {
			width: 100%;
		}

		.filters {
			padding-top: 4px;

			> div {
				padding-left: 0;
				padding-right: 0;

				&.button {
					min-width: 10px;
				}
				@include media-breakpoint-down(sm) {
					width: 100% !important;
				}
			}

			.form-group-extra,
			.form-control {
				@include media-breakpoint-down(sm) {
					width: 100% !important;
				}
			}
		}

		.actions {
			> div {
				padding: 5px 0;

				@include media-breakpoint-down(sm) {
					// width: 100%;
				}
			}
		}

		.expand-separator {
			display: block;
		}

		&.has-expand {
			.filters,
			.filter-separator {
				display: none !important;
			}
		}

		&.expanded {
			.filters,
			.filter-separator {
				display: block !important;
			}

			.filter-expand {
				i {
					transform: rotate(180deg);
				}
			}
		}
	}

	.project-list-filter,
	.item-search-filter {
		.form-label {
			width: 110px;
		}
	}

	.time-entry-list-filter {
		.form-label {
			width: 80px;
		}
	}
}

@include media-breakpoint-down(lg) {
	.a-filter {
		.filters:not(.no-auto) {
			> div {
				flex: auto;
			}
		}

		&:not(.no-separator) {
			.separator {
				width: 100%;
				margin: 12px 0;
				&:not(.expand-separator) {
					display: block;
				}

				&::after {
					content: ' ';
					position: absolute;
					width: 100%;
					height: 1px;
					left: 0px;
					background-color: $-color-sand;
				}
			}
			.actions {
				width: 100%;
			}
		}
	}
}

@include media-breakpoint-down(
	x,
	(
		'x': 2250px,
	)
) {
	.a-filter {
		&.project-item-view-filter {
			@include list-custom-responsive();
		}
	}
}

@include media-breakpoint-down(
	x,
	(
		'x': 2230px,
	)
) {
	.a-filter {
		&.task-appointment-filter {
			@include list-custom-responsive-no-zoomer();
			.form-label {
				min-width: 75px;
			}
		}
	}
}

@include media-breakpoint-down(
	x,
	(
		'x': 1750px,
	)
) {
	.a-filter {
		&.item-search-filter,
		&.time-entry-list-filter {
			@include list-custom-responsive-no-zoomer();

			.filters {
				> div {
					flex: auto;
				}
			}
		}
	}
}

@include media-breakpoint-down(
	x,
	(
		'x': 1360px,
	)
) {
	.a-filter {
		&.checkbook-list-filter {
			@include list-custom-responsive-no-zoomer();
		}
	}
}

@include media-breakpoint-down(
	x,
	(
		'x': 1380px,
	)
) {
	.a-filter {
		&.credit-card-list-filter {
			@include list-custom-responsive-no-zoomer();
		}
	}
}

@include media-breakpoint-down(
	x,
	(
		'x': 1390px,
	)
) {
	.a-filter {
		&.project-vendor-orders-filter {
			@include list-custom-responsive-no-zoomer();
		}
	}
}

.a-filter {
	@include media-breakpoint-down(
		x,
		(
			'x': 1800px,
		)
	) {
		&.project-proposal-add-proposal-filter {
			@include list-custom-responsive-no-zoomer();
		}
	}

	@include media-breakpoint-down(
		x,
		(
			'x': 1300px,
		)
	) {
		&.project-proposal-add-layout-filter {
			@include list-custom-responsive-no-zoomer();
		}
	}

	&.list-breakpoint {
		@include list-custom-responsive-no-zoomer();
	}

	&.filter-client-invoices-add {
		@include list-custom-responsive-no-zoomer();
		label {
			min-width: 70px;
		}

		@include media-breakpoint-down(lg) {
			.filters {
				width: 100%;
				> div {
					width: 100%;
				}
			}
		}
	}
}
