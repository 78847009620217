.a-filter {
	padding: 14px 8px;
	position: relative;
	z-index: $z-index-2;

	.filter-expand {
		display: none;
	}

	.form-label {
		margin-bottom: 0px;
		margin-right: 10px;
		white-space: nowrap;
		max-width: 300px;

		@include media-breakpoint-down(xxl) {
			white-space: normal;
		}
	}

	.react-select-header {
		width: 100%;
	}

	@include media-breakpoint-up(lg) {
		.form-select,
		.form-control {
			max-width: 300px;
		}
	}

	.separator {
		display: none;
	}

	.filters {
		> div {
			padding: 5px 12px;
			min-width: 25%;

			&:first-child {
				padding-left: 0px;
			}

			&.button {
				min-width: 60px;
				width: auto;
			}
		}

		&.width-20 {
			> div {
				width: 20%;
			}
		}

		&.width-25 {
			> div {
				width: 25%;
			}
		}

		&.width-30 {
			> div {
				width: 30%;
			}
		}

		&.width-33 {
			> div {
				width: 33.33%;
			}
		}

		&.width-50 {
			> div {
				width: 50%;
			}
		}

		&.width-75 {
			> div {
				width: 75%;
			}
		}

		&.width-100 {
			> div {
				width: 100%;
			}
		}

		@include media-breakpoint-up(
			custom,
			(
				custom: 2100px,
			)
		) {
			> div {
				min-width: 300px !important;
			}
		}
	}
}
