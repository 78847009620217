.a-tree-parent {
	list-style-type: none;
	padding: 0;
	margin: 0;

	.editLink {
		display: none;
		font-size: 12px;
		opacity: 0.6;
		text-decoration: none;
		margin-left: 0.5rem;
		&:hover {
			text-decoration: underline;
		}
	}
	.a-tree-sub {
		.editLink {
			display: inline;
		}
	}

	ul {
		display: none;
		list-style-type: none;
		padding-left: 30px;
	}

	li {
		padding: 12px 0 0px;
	}

	.btn {
		padding: 3px 6px 5px;
		min-width: 1px;
		line-height: 1;
		min-height: 20px;

		&,
		&:focus,
		&:active,
		&:focus-visible {
			border: 0px;
			outline: none;
			box-shadow: none;
			color: #1a1b1f;
		}

		i {
			position: relative;
			top: 2px;
			font-family: 'remixicon' !important;
			font-style: normal;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;

			&::before {
				content: '\ed61';
			}
		}

		span[class^='ri-'] {
			position: relative;
			top: 2px;
		}

		&.empty-node {
			i {
				&::before {
					content: '\ECE0';
				}
			}
		}
	}

	.active-node {
		> ul {
			display: block;
		}

		> .btn {
			background-color: $-color-beige;

			i {
				&::before {
					content: '\ED6F';
				}
			}
		}
	}

	.btn {
		&:not(.active-btn) {
			&:hover,
			&:active,
			&:focus {
				background-color: $-color-primary-green;
				color: white;
			}
		}

		&.hover-link {
			&.active-btn,
			&:hover {
				background-color: $-color-primary-green;
				color: white;
				padding: 3px 12px 5px 6px;

				.hover-view-icon {
					text-decoration: underline;
					position: relative;
				}

				.hover-view-icon {
					&:after {
						content: '\ECB4';
						font-family: 'remixicon' !important;
						font-style: normal;
						-webkit-font-smoothing: antialiased;
						opacity: 0.5;
						margin-left: 3px;
						position: absolute;
						bottom: 0;
						color: $-color-ivory;
						font-size: 90%;
					}
				}
			}
		}
	}

	/**********************************
	 Style/Design 2
    *********************************/

	/** Customize open and close icon */
	/** Display plus and minus icon expand */
	&.style-2 {
		& > li.active-node {
			& > .a-tree-sub {
				& > li {
					& > .btn {
						i {
							&::before {
								display: inline;
								content: '\EA0F';
							}
						}
					}
					&.active-node {
						& > .btn {
							i {
								&::before {
									display: inline;
									content: '\EB84';
								}
							}
						}
					}
				}
			}
		}

		.btn {
			i {
				&::before {
					content: '\EA0F';
				}
			}

			&.empty-node {
				i {
					&::before {
						display: none;
					}
				}
			}
		}

		.active-node {
			> .btn {
				i {
					&::before {
						content: '\EB84';
					}
				}
			}
		}
	}
}

.report-tree-parent {
	list-style-type: none;
	padding: 0;
	margin: 0;

	ul {
		list-style-type: none;
		padding-left: 30px;

		i {
			position: relative;
			top: 2px;
			font-family: 'remixicon' !important;
			font-style: normal;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			color: $-color-primary-green !important;
		}

		li {
			padding: 12px 0px 0px;
		}
	}

	.report-header {
		background-color: $-color-beige;
		margin: 5px;
		padding: 5px 10px;
	}

	.btn {
		padding: 3px 5px;
		min-width: 1px;
		line-height: 1;
		min-height: 20px;
		margin-left: 2px;
		margin-bottom: 2px;

		&,
		&:focus,
		&:active,
		&:focus-visible {
			border: 0px;
			outline: none;
			box-shadow: none;
			color: #1a1b1f;
		}

		span[class^='ri-'] {
			position: relative;
			top: 2px;
		}
	}

	.btn {
		&:not(.active-btn) {
			&:hover,
			&:active,
			&:focus {
				background-color: $-color-primary-green;
				color: white;
			}
		}

		&.active-btn,
		&:hover {
			background-color: $-color-primary-green;
			color: white;

			.hover-view-icon {
				text-decoration: underline;
				position: relative;
			}

			.hover-view-icon {
				&:after {
					content: '\ECB4';
					font-family: 'remixicon' !important;
					font-style: normal;
					-webkit-font-smoothing: antialiased;
					opacity: 0.5;
					margin-left: 3px;
					position: absolute;
					bottom: 0;
					color: $-color-ivory;
					font-size: 90%;
				}
			}
		}
	}
}
