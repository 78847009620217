/** Mobile */
@include media-breakpoint-down($-app-responsive-tablet-size) {
	.sidemenu {
		padding-top: 0px;

		.side-nav {
			padding: 0 $-app-nav-padding;
		}
	}

	.sidemenu-impersonation {
		padding-top: 30px !important;
	}

	.topmenu-wrapper {
		height: $-app-topbar-height-mobile;

		.navbar-brand {
			img {
				width: 110px;
				margin-left: $-app-content-padding-mobile;
			}
		}

		.navbar-toggler {
			&.menu-toggle {
				width: 26px;
				right: $-app-content-padding-mobile;
			}
		}
	}
}
