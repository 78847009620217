.pagination {
	border: 1px solid $-color-sand;
	border-radius: 4px;
}

.pagination-btn {
	width: 100px;
	border: 0;
}

.pagination-span {
	min-width: 100px;
	text-align: center;
	border-left: 1px solid $-color-sand;
	border-right: 1px solid $-color-sand;
}

.pagination-select > div {
	border: 1px solid $-color-sand;
}
