.a-floating-help {
	position: fixed;
	right: 25px;
	bottom: 3%;
	z-index: 120;
	transition: bottom 0.45s ease;

	.btn {
		width: 80px;
		min-width: 1px;
		height: 80px;
		padding: 0px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 200px;
		font-size: 22px;
		transition: box-shadow 0.45s ease;

		&:hover {
			box-shadow: 0px 0px 30px 4px #0000003a;
		}
	}
}

.app-contains-form {
	&.app-scrolled-bottom {
		.a-floating-help {
			bottom: 18%;
		}
	}
}
