.a-header {
	padding: $-app-content-padding $-app-content-padding 15px;

	.a-header-title {
		position: relative;
		.a-header-cta {
			ul {
				margin-left: -8px;
				margin-right: -8px;

				li {
					padding: 0 8px;
				}
			}

			.dropdown-menu {
				.dropdown-item {
					font-size: 15px;
				}
			}
		}

		.a-header-breadcrumb {
			button {
				padding: 0;
				min-height: auto;
				min-width: auto;
				margin-bottom: 16px;
			}
		}
	}

	.a-header-buttons {
		margin-left: -5px;
		margin-right: -5px;
		flex-wrap: nowrap;
		max-width: 100%;
		overflow-x: auto;
		overflow-y: hidden;
		height: 48px;

		li {
			padding: 0 5px;
			white-space: nowrap;

			a {
				background-color: transparent;
				border-color: transparent;

				&:hover,
				&.active {
					background-color: rgba(#fff, 0.15);
				}
			}
		}
	}

	&.light {
		.a-header-buttons {
			li {
				a {
					background-color: transparent;
					border-color: transparent;
					color: inherit;
					box-shadow: none;

					&:hover,
					&.active {
						box-shadow: none;
						background-color: rgba($-color-charcoal, 0.1) !important;
					}
				}
			}
		}

		.breadcrumb {
			margin-bottom: 0px;
		}
	}
}
