.payment-settings {
	.image-wrapper {
		> div {
			padding: 50px 0px;

			img {
				@include media-breakpoint-down(sm) {
					max-width: 100%;
				}
			}
		}
	}
}

.company-settings {
	.dropzone-content {
		min-height: 180px;
	}
}
