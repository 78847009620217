.a-table {
	> :not(caption) > * > * {
		padding: 22px 17px;
		vertical-align: middle;
	}

	thead,
	tbody {
		position: relative;
	}

	thead {
		z-index: 12;

		th {
			padding: 20px 17px;

			&.description {
				min-width: 250px;
			}

			.sort {
				cursor: pointer;

				&::after {
					content: '\EA4D';
					font-family: 'remixicon' !important;
					font-style: normal;
					-webkit-font-smoothing: antialiased;
					opacity: 0.5;
					margin-left: 3px;
					position: absolute;
					color: $-color-secondary-ash;
				}

				&.active {
					&::after {
						color: #000;
					}
				}

				&.desc {
					&::after {
						content: '\EA77';
					}
				}
			}
		}
		.a-table-heading {
			th {
				border-bottom: 0px;
			}
		}

		.a-table-heading-modal-no-border {
			th {
				border-bottom: 0px;
			}
		}

		.a-table-search-fields {
			th {
				padding-top: 2px;
			}
		}
	}

	tbody {
		/* Increase visibility priority */
		&:hover {
			z-index: 14;
		}

		td {
			.badge {
				min-width: 70px;
			}

			img.img-sm {
				width: 70px;
			}

			a {
				text-decoration: underline;
			}
		}

		> tr {
			transition: box-shadow 0.35s ease-in-out;
			position: relative;
			z-index: 12;

			> td {
				position: relative;

				&:first-child {
					&::before {
						// transition: background-color 0.25s ease-in-out;
						// position: absolute;
						// content: " ";
						// left: 1.5px;
						// top: 1px;
						// width: 6px;
						// height: 98%;
						// background-color: transparent;
						// border-radius: 10px;
					}
				}
			}

			&.active,
			&:hover {
				box-shadow: 0px 4px 20px #00000032;
				z-index: 20;

				> td {
					&:first-child {
						&::before {
							background-color: $-color-primary-green;
						}
					}

					.tr-hover-visible {
						visibility: visible;
						opacity: 1;
					}

					a:not(.no-after) {
						&.hover-view-icon:hover {
							position: relative;
							/*
                            &:after {
                                content: "\ECB4";
                                font-family: 'remixicon' !important;
                                font-style: normal;
                                -webkit-font-smoothing: antialiased;
                                opacity: .5;
                                margin-left: 3px;
                                position: absolute;
                                bottom: -2px;
                                color: $-color-secondary-sand;
                            }
                            */
						}
					}
				}
			}
		}
	}

	.sticky-cell {
		position: -webkit-sticky;
		position: sticky;
		z-index: 2;
	}

	&.table-striped > tbody > tr:nth-of-type(even) > * {
		box-shadow: inset 0 0 0 9999px #faf9f7;
	}

	&.table-striped > tbody > tr:nth-of-type(odd) > * {
		box-shadow: none;
	}

	&.clickable-row {
		tr {
			cursor: pointer;
		}
	}

	&.table-check {
		tbody {
			> tr {
				> td {
					&:first-child {
						&::before {
							transition: background-color 0.25s ease-in-out;
							position: absolute;
							content: ' ';
							left: 1.5px;
							top: 1px;
							width: 6px;
							height: 98%;
							background-color: transparent;
							border-radius: 10px;
						}
					}
				}

				&.active,
				&:hover {
					> td {
						&:first-child {
							&::before {
								background-color: $-color-primary-green;
							}
						}
					}
				}
			}
		}
	}
}

.table-responsive {
	padding-left: 12px;
	padding-right: 12px;
	margin-left: -12px;
	margin-right: -12px;
	overflow-x: auto !important;
}

.table-responsive-full {
	.table-responsive {
		width: 100%;
	}
}

.table-scrolled-gradient {
	position: relative;

	&:after {
		opacity: 0;
		transition: all 0.25s ease-in-out;
	}

	&:not(.table-scrolled-end):after {
		opacity: 1;
		content: '';
		position: absolute;
		z-index: 100;
		right: -12px;
		top: 0;
		height: 100%;
		width: 110px;
		background: -moz-linear-gradient(
			left,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 1) 100%
		);
		background: -webkit-linear-gradient(
			left,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 1) 100%
		);
		background: linear-gradient(
			to right,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 1) 100%
		);
	}
}

.table-stockitem-columns {
	thead th,
	tbody td {
		background-color: white;
		min-width: 140px;
		width: 140px;

		&:nth-of-type(1) {
			left: 0px;
			min-width: 70px;
			width: 70px;
		}
		&:nth-of-type(2) {
			left: 70px;
			min-width: 110px;
			width: 110px;
		}
		&:nth-of-type(3) {
			left: 180px;
			min-width: 110px;
			width: 110px;
		}
		&:nth-of-type(4) {
			left: 290px;
			min-width: 110px;
			width: 110px;
		}
		&:nth-of-type(5) {
			left: 400px;
			min-width: 120px;
			width: 120px;
		}
		&:nth-of-type(6) {
			left: 520px;
			min-width: 140px;
			width: 140px;
		}
		&:nth-of-type(7) {
			left: 660px;
			min-width: 250px;
			width: 250px;
		}
		&:nth-of-type(8) {
			left: 910px;
			min-width: 110px;
			width: 110px;
		}
		&:nth-of-type(9) {
			left: 1020px;
			min-width: 110px;
			width: 110px;
		}
		&:nth-of-type(10) {
			left: 1130px;
			min-width: 110px;
			width: 110px;
		}

		@include media-breakpoint-down(
			x,
			(
				x: 1501px,
			)
		) {
			&.sticky-cell {
				position: inherit;
				z-index: inherit;
				left: auto;
			}
			&:nth-of-type(10)::after {
				display: none;
			}
		}
	}
}

.table-scrolled-gradient {
	.table-stockitem-columns {
		thead th,
		tbody td {
			&:nth-of-type(10)::after {
				box-shadow: 26px 0px 20px #00000011;
				transition: background-color 0.25s ease-in-out;
				position: absolute;
				content: ' ';
				right: 2px;
				top: 0;
				width: 40px;
				height: 100%;
				background-color: transparent;
			}
		}
	}
}

.align-right {
	text-align: right;
}

div .a-filter {
	z-index: $z-index-3;
}

.sticky-container .table-responsive {
	max-height: 65vh;
	display: block;
	thead {
		position: sticky;
		top: 0;
		z-index: $z-index-2;
	}

	@media (min-height: 1200px) {
		max-height: 75vh;
	}
}
