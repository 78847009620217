/** Grid points */
$grid-breakpoints: (
	xs: 0,
	xsm: 400px,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px,
	xxxl: 1680px,
	xxxxl: 1820px,
	xxxxxl: 1900px,
);

$-primary-color: #008b6d;
$-lightgrey-color: #eeede9;
$-darkgrey-color: #7c7c7c;
$-secondary-color: #e7faf3;
$-secondary-text-color: #006a53;
$-inactive-color: #dedcd7;
$-inactive-text-color: #7c7c7c;
$-dark-color: #211f21;

/** Palette */
$-color-primary-green: #008b6d;
$-color-secondary-green: #006a53;
$-color-tertiary-green: #e7faf3;
$-color-primary-violet: #000e8d;
$-color-secondary-violet: #000b6b;
$-color-tertiary-violet: #e4e6f8;
$-color-primary-yellow: #ffd43e;
$-color-secondary-yellow: #ccaa32;
$-color-tertiary-yellow: #fff6d8;
$-color-primary-red: #f25438;
$-color-secondary-red: #c2432d;
$-color-tertiary-red: #fff1ee;
$-color-primary-blue: #176de5;
$-color-secondary-blue: #104ca0;
$-color-tertiary-blue: #e8f0fc;
$-color-charcoal: #211f21;
$-color-primary-ash: #484747;
$-color-secondary-ash: #7c7c7c;
$-color-sand: #dedcd7;
$-color-secondary-sand: #b3b3b3;
$-color-beige: #eeede9;
$-color-ivory: #faf9f7;
$-color-white: #ffffff;
$-color-muted-primary: rgba(26, 27, 31, 0.5);
$-color-dark-primary: #1a1b1f;
$-color-placeholder: #84878a;

$-colors: (
	'primary': $-color-primary-green,
	'secondary': $-color-tertiary-green,
	'primary-grey': $-color-secondary-ash,
	'secondary-grey': $-color-beige,
	'primary-green': $-color-primary-green,
	'secondary-green': $-color-secondary-green,
	'tertiary-green': $-color-tertiary-green,
	'primary-violet': $-color-primary-violet,
	'secondary-violet': $-color-secondary-violet,
	'tertiary-violet': $-color-tertiary-violet,
	'primary-yellow': $-color-primary-yellow,
	'secondary-yellow': $-color-secondary-yellow,
	'tertiary-yellow': $-color-tertiary-yellow,
	'primary-red': $-color-primary-red,
	'secondary-red': $-color-secondary-red,
	'tertiary-red': $-color-tertiary-red,
	'primary-blue': $-color-primary-blue,
	'secondary-blue': $-color-secondary-blue,
	'tertiary-blue': $-color-tertiary-blue,
	'charcoal': $-color-charcoal,
	'primary-ash': $-color-primary-ash,
	'secondary-ash': $-color-secondary-ash,
	'sand': $-color-sand,
	'secondary-sand': $-color-secondary-sand,
	'beige': $-color-beige,
	'ivory': $-color-ivory,
	'dark': $-color-charcoal,
	'white': $-color-white,
	'muted-primary': $-color-muted-primary,
	'dark-primary': $-color-dark-primary,
	'placeholder': $-color-placeholder,
);

$-colors-gadient: (
	'ivory': linear-gradient(180deg, #eeeeee 0%, rgba(250, 249, 247, 1) 100%),
	'secondary-green':
		linear-gradient(180deg, #005f4a 0%, rgba(0, 106, 83, 1) 100%),
);

/** Fonts */
$-font-medium: 'DM Serif Text';
$-font-bold: 'DM Serif Text';
$-font-body: 'Public Sans', sans-serif;
$-font-size: 0.8rem; /* 14px */
$-font-sizes: (
	'sm': 0.75rem,
	'md': 0.8rem,
	'lg': 1rem,
	'xl': 1.125rem,
	'xxl': 1.25rem,
	'xxxl': 1.375rem,
	'xxx1l': 1.5rem,
	'xxx2l': 1.625rem,
); /* 12px, 14px, 16px, 18px, 20px, 22px, 24px, 26px */
$-font-weight: 300 500 600 700;
$font-weight-bolder: 600;

/** Sizes */
$-app-sidebar-width: 232px;
$-app-topbar-height: 80px;
$-app-topbar-height-mobile: 65px;
$-app-responsive-size: xl;
$-app-responsive-tablet-size: xl;
$-app-responsive-mobile-size: md;
$-app-responsive-xxl-size: xxl;

/** Margin/Padding */
$-app-content-padding: 40px;
$-app-content-padding-mobile: 20px;
$-app-nav-padding: 10px;

/** Utilities */
$-border-radius-range: 2 4 6 8 10; /* px */

$z-index-0: 10;
$z-index-1: 100;
$z-index-2: 200;
$z-index-3: 300;
$z-index-4: 400;
$z-index-list: $z-index-0 $z-index-1 $z-index-2 $z-index-3 $z-index-4;
$z-index-top-menu: 9999;
$z-index-side-menu: 9997;
