@-webkit-keyframes moving-gradient {
	0% {
		background-position: -250px 0;
	}
	100% {
		background-position: 250px 0;
	}
}

@keyframes moving-gradient {
	0% {
		background-position: -250px 0;
	}
	100% {
		background-position: 250px 0;
	}
}

table {
	tr.tr-loading {
		border-bottom: 1px solid rgba(0, 0, 0, 0.055);

		&:hover {
			box-shadow: none !important;
		}

		td {
			height: 50px;
			vertical-align: middle;
			padding: 8px;
			background-color: white !important;
			box-shadow: none !important;

			span {
				display: block;
			}

			&.td-1 {
				width: 20px;

				span {
					height: 34px;
					background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
					background-size: 500px 100px;
					animation-name: moving-gradient;
					animation-duration: 1s;
					animation-iteration-count: infinite;
					animation-timing-function: linear;
					animation-fill-mode: forwards;

					&.image {
						width: 50px;
						height: 50px;
					}

					&.checkbox {
						width: 20px;
						height: 20px;
						margin: auto auto;
					}
				}
			}
		}

		&.compact {
			&,
			td {
				border: 0px;
			}

			td {
				height: 30px;
				padding-top: 4px;
				padding-bottom: 4px;

				&.td-1 {
					span {
						height: 26px;
					}
				}
			}

			&:last-child {
				td {
					padding-bottom: 0px;
				}
			}
		}
	}

	tr.tr-loading-1 {
		td {
			span {
				display: block;
			}

			&.td-1 {
				width: 20px;

				span {
					height: 34px;
					background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
					background-size: 500px 100px;
					animation-name: moving-gradient;
					animation-duration: 1s;
					animation-iteration-count: infinite;
					animation-timing-function: linear;
					animation-fill-mode: forwards;

					&.image {
						width: 50px;
						height: 50px;
					}

					&.checkbox {
						width: 20px;
						height: 20px;
						margin: auto auto;
					}
				}
			}
		}
	}
}

span.skeleton-loader {
	height: 34px;
	background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
	background-size: 500px 100px;
	animation-name: moving-gradient;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	animation-fill-mode: forwards;

	&.image {
		width: 100%;
		height: 100%;
		display: block;
	}
}
