.dropzone {
	&-wrapper {
		border: 1px solid #dedcd7;
		border-radius: 4px;
		padding: 20px;
	}
	&-content {
		border: 1px dashed #dedcd7;
		border-radius: 4px;
		padding: 20px;
		min-height: 300px;
		color: #7c7c7c;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-weight: 600;
		font-size: 16px;
		position: relative;
		i {
			font-size: 40px;
			font-weight: normal;
		}
	}

	&-image {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		background-color: white;
	}
}
