.a-mini-zoomer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	min-width: 80px;

	> span {
		&:first-child {
			padding-right: 5px;
			cursor: pointer;
		}

		&:last-child {
			padding-left: 5px;
			cursor: pointer;
		}
	}

	.zoom-slide {
		width: 100%;
		height: 2px;
		background-color: #dcdcda;
		position: relative;

		span {
			position: absolute;
			left: 50%;
			top: -3px;
			height: 8px;
			width: 8px;
			border-radius: 150px;
			background-color: #494946;

			transform: translateX(-50%);
			transition: all 0.45s ease-out;
		}
	}

	&.zoom-disabled {
		span {
			color: #b4b4b4;
			cursor: default;
		}
		.zoom-slide {
			background-color: #e3e3e1;

			span {
				background-color: #b4b4b4;
			}
		}
	}

	&.zoom-0 {
		.zoom-slide {
			span {
				left: 0%;
			}
		}
	}

	&.zoom-2 {
		.zoom-slide {
			span {
				left: 100%;
			}
		}
	}
}
