@media (min-width: 1000px) {
	#role-popover-contained {
		left: 150px !important;
		top: 10px !important;
	}
}

#role-popover-contained {
	min-width: 500px;
}
#role-popover-contained > .popover-arrow {
	display: none;
}
