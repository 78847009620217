.a-input-bar {
	max-width: 850px;

	&.full {
		max-width: 100%;
	}

	&.xsmall {
		max-width: 400px;
	}

	&.small {
		max-width: 600px;
	}

	&.medium {
		max-width: 920px;
	}

	&.large {
		max-width: 1200px;
	}

	&.xlarge {
		max-width: 1600px;
	}

	> ul {
		width: 100%;

		> li {
			padding: 17px 32px;
			position: relative;

			li {
				padding: 0px 15px;
			}

			> ul > li {
				width: 100%;

				&:last-child {
					padding-right: 0px;
				}

				&:first-child {
					padding-left: 0px;
				}
			}

			label.ilabel {
				text-align: right;
				margin-right: 8px;
				margin-bottom: 0px;
			}

			.form-check {
				.form-check-label {
					top: 0px;
				}
			}

			&.full-width {
				width: 100%;
			}

			&:not(.full-width):not(.auto-width) {
				max-width: 700px;
			}

			* {
				font-size: 12px;
			}

			&.inactive:not(:first-child)::before {
				content: ' ';
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				width: 100%;
				background-color: $-color-beige;
				z-index: 12;
			}

			> ul {
				position: inherit;
			}
		}

		.three {
			li {
				width: 33.33%;
			}
		}
	}
}

.a-input-bar-float {
	position: fixed;
	bottom: 80px;
	padding-left: $-app-sidebar-width;
	left: 50%;
	transform: translateX(-50%);

	.a-action-bar {
		transform: translateX(-50%);
	}
}
