.a-footer-action {
	position: absolute;
	left: 0;
	width: 100%;
	padding-left: $-app-sidebar-width;

	.a-footer-action-inner {
		display: flex;
		padding: $-app-content-padding;

		.btn {
			width: 280px;
			max-width: 100%;
		}
	}
}
