.ff-close-dropdown {
	background-color: $-color-sand;
	border-radius: 5px;
	color: $-color-dark-primary;
	padding: 3px 0 0 0;
	text-align: center;
	text-decoration: none;
	min-width: 20px;
}
.ff-combobox {
	position: relative;

	.total-selected {
		background-color: $-color-primary-green;
		border-radius: 50%;
		color: white;
		height: 15px;
		font-size: 12px;
		font-weight: bold;
		position: absolute;
		right: 30px;
		text-align: center;
		width: 15px;
	}

	&.ff-fixed-combo {
		.total-selected {
			right: 22px !important;
		}
	}

	.ff-combobox-dropdown {
		background: #ffffff;
		border: solid 1px #dedcd7;
		border-radius: 5px;
		box-shadow: 0 0 5px #cccccc;
		margin-top: 2px;
		max-width: 300px;
		min-width: 200px;
		overflow: hidden;
		position: absolute;
		z-index: 9999;

		&.ff-pos-right {
			right: 0;
		}

		.ff-combobox-dropdown-header {
			border-bottom: solid 1px #dedcd7;

			.ff-combobox-dropdown-header-title {
				background-color: #faf9f7;
				padding: 10px 15px;
			}

			.search-input {
				background-color: transparent;
				border: none;
				font-size: 0.875rem;
				line-height: 1.25rem;

				&:focus {
					box-shadow: none !important;
					outline: none !important;
				}
			}
		}
		.ff-combobox-dropdown-body {
			padding: 5px;

			ul {
				max-height: 200px;
				min-height: 100px;
				margin: 0;
				overflow-x: scroll;
				padding: 0;

				&::-webkit-scrollbar {
					width: 3px;
				}

				li {
					list-style-type: none;
					.form-check {
						border-radius: 5px;
						color: #212529;
						font-size: 14px;
						padding: 10px;
						text-decoration: none;

						&:hover {
							background: #eeeeee;
						}

						.form-check-input {
							margin-left: 0 !important;
						}
						label {
							display: block;
							padding-left: 20px;
							position: relative;
							top: 0px;
						}
					}
				}
			}
		}
	}
}
