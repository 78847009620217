@include media-breakpoint-down($-app-responsive-mobile-size) {
	.a-box {
		.a-box-basis {
			padding: 0px;
		}

		.card-text {
			min-height: 90px;
			justify-content: center !important;
		}

		.a-box-cards {
			$--margin: 8px;
			margin-top: -($--margin * 2);

			> .a-box-cards-basis {
				padding-top: $--margin * 2;
			}
		}
	}
}
