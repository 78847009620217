.a-switch {
	position: relative;

	input {
		-webkit-appearance: none;
		appearance: none;
		width: 64px;
		padding-left: 33px;
		padding-top: 2px;
		padding-bottom: 2px;
		height: 28px;
		margin: 0;
		border-radius: 16px;
		// background: radial-gradient(circle 12px, white 100%, transparent calc(100% + 1px)) #ccc -18px;

		transition: 0.3s ease-in-out;
		background-color: #ccc;

		&::after {
			content: ' ';
			position: absolute;
			width: 24px;
			height: 24px;
			background-color: white;
			border-radius: 16px;
			top: -1.5px;
			transition: all 0.3s ease-in-out;
		}

		&:not(:checked)::after {
			left: 2px;
		}

		&:checked::after {
			left: 60%;
		}
	}

	input::before {
		content: 'Off';
		white-space: pre;
		color: #211f21;
		font-size: 14px;
		position: relative;
		top: 3px;
		transition: color 0.8s ease;
	}

	input:checked {
		padding-left: 8px;
		background-color: $-primary-color;
		background-position: 18px;
	}

	input:disabled {
		background: radial-gradient(
				circle 12px,
				#eeede9 100%,
				transparent calc(100% + 1px)
			)
			#dedcd7 -18px;
	}

	input:checked:disabled {
		background-position: 18px;
	}

	input:checked::before {
		content: 'On';
		color: white;
	}

	input:disabled:before {
		color: white;
	}
}

.multisearch-switch {
	.a-switch {
		input {
			width: 48px;
			height: 24px;

			&::after {
				width: 20px;
				height: 20px;
				top: 2px;
			}

			&::before {
				top: 2px;
				right: 8px;
				font-size: 13px;
			}

			&:checked::after {
				left: 55%;
			}
		}
	}
}
