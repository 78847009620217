.modal {
	z-index: $z-index-top-menu + 1;
}

.modal.left .modal-dialog,
.modal.right .modal-dialog {
	position: fixed;
	margin: auto;
	width: 100%;
	height: 100%;
	max-width: 700px;
	-webkit-transform: translate3d(0%, 0, 0);
	-ms-transform: translate3d(0%, 0, 0);
	-o-transform: translate3d(0%, 0, 0);
	transform: translate3d(0%, 0, 0);

	.modal-content {
		height: 100%;
		overflow-y: auto;
		border-radius: 0px;
	}

	.modal-body,
	.modal-body {
		padding: 15px 15px 60px;
	}
}

/* Left */
.modal.left.fade .modal-dialog {
	left: -320px;
	-webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
	-moz-transition: opacity 0.3s linear, left 0.3s ease-out;
	-o-transition: opacity 0.3s linear, left 0.3s ease-out;
	transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.show .modal-dialog {
	left: 0;
}

/* Right */
.modal.right.fade .modal-dialog {
	right: -320px;
	-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
	-moz-transition: opacity 0.3s linear, right 0.3s ease-out;
	-o-transition: opacity 0.3s linear, right 0.3s ease-out;
	transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
	right: 0;
}

.modal.modal-full {
	.modal-dialog {
		width: 100%;
		max-width: none;
		height: 100%;
		margin: 0;
	}
}

.modal.modal-third {
	.modal-dialog {
		width: 100%;
		max-width: 33.3%;
	}
}

.modal.modal-40 {
	.modal-dialog {
		width: 100%;
		max-width: 40%;
	}
}

.modal.modal-half {
	.modal-dialog {
		width: 100%;
		max-width: 50%;
	}
}

.modal.modal-quarter {
	.modal-dialog {
		width: 100%;
		max-width: 75%;
	}
}

.modal {
	.modal-dialog {
		@include media-breakpoint-down(md) {
			max-width: 100% !important;
		}
	}
}
