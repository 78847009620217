.documents-grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 10px;

	@include media-breakpoint-up(sm) {
		grid-template-columns: repeat(3, 1fr);
	}

	@include media-breakpoint-up(lg) {
		grid-template-columns: repeat(min(4, var(--columns)), 1fr);
	}

	@include media-breakpoint-up(xl) {
		grid-template-columns: repeat(var(--columns), 1fr);
	}
}

.documents-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.7em;
}

.documents-grid .documents-icon {
	width: 40px;
}

td .documents-icon {
	width: 40px;
}

.documents-image-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 96px;
	height: 96px;
	border-radius: 48px;
	background-color: $-color-ivory;
}

.documents-dropzone {
	min-height: 300px;
	border: 2px dashed $-inactive-color;
	border-radius: 12px;

	.table-responsive:last-child {
		padding-bottom: 100px;
	}
}

.documents-dropzone-overlay {
	height: 300px;
	pointer-events: none;
	z-index: 9999;
}

.documents-progressbar {
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 3px;
	bottom: 24px;
	z-index: 10001;

	div {
		span {
			font-size: 0.85em;
			font-weight: 700;
		}

		div {
			height: 3px;
			border-radius: 1px;

			div {
				width: var(--progress);
			}
		}
	}
}

.documents-thumbnail > img {
	width: 40px;
}

#react-doc-viewer {
	background-color: transparent !important;
}

#image-renderer {
	background-color: transparent !important;
	background-image: none !important;
}

.documents-viewer .modal-dialog > .modal-content {
	background-color: rgba(0, 0, 0, 0.8) !important;
}

.documents-viewer .modal-content > .modal-header {
	background-color: black;
	border: 0;
}

.documents-viewer #pdf-download {
	visibility: hidden;
}
